import { Component, inject } from '@angular/core';
import { Store } from '@ngrx/store';
import { RootState } from '@store/index';
import { IS_SERVER } from '@core/config';

@Component({ template: '' })
export abstract class ReactiveComponent {
  protected readonly store: Store<RootState> = inject(Store);
  protected readonly isServer: boolean = inject(IS_SERVER);
  protected readonly i18nRootKey: string = '';

  public i18n(key: string): string {
    return [this.i18nRootKey, key].join('.');
  }
}
